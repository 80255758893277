<template>
  <div class="d-flex flex-column">
    <h4 class="font-weight-bold py-5">{{ title }}</h4>
    <b-form class="form" v-on:submit.prevent="save">
      <!-- begin:Name  -->
      <b-form-group label="Away Name">
        <b-form-input
          class="form-control h-auto"
          v-model="$v.form.name.$model"
          :state="validateState('name')"
          placeholder="Enter Away Name"
        >
        </b-form-input>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:Name  -->
      <b-form-group label="Abbreviation">
        <b-form-input
          class="form-control h-auto"
          v-model="$v.form.abbreviation.$model"
          :state="validateState('abbreviation')"
          placeholder="Enter Away Abbreviation"
        >
        </b-form-input>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:description  -->
      <b-form-group :label="$t('DETAILSFORM.DESC')">
        <b-form-textarea
          class="form-control h-auto"
          :placeholder="$t('DETAILSFORM.DESC1')"
          rows="3"
          max-rows="6"
          v-model="$v.form.description.$model"
          :state="validateState('description')"
        >
        </b-form-textarea>
      </b-form-group>
      <!-- end:Name  -->

      <!-- begin:away unit  -->
      <b-form-group label="Away Unit">
        <b-form-select
          v-model="$v.form.awayunit.$model"
          :options="awayunits"
          :state="validateState('awayunit')"
        >
        </b-form-select>
        <b-form-invalid-feedback v-if="!$v.form.awayunit.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <!-- end:away unit  -->

      <!--begin::Action-->
      <div class="form-group d-flex flex-column">
        <b-button
          variant="primary"
          type="submit"
          ref="department-form-submit"
          class="font-weight-bolder"
          v-bind:class="{ 'spinner spinner-light spinner-right': isBusy }"
        >
          {{ $t("COMPANY.REGISTRATION.SUBMIT") }}
        </b-button>
      </div>
      <!--end::Action-->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
export default {
  name: "CompanyAwayDetailCreateForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        awayunit: "",
        abbreviation: "",
      },
      awayunits: [
        { value: "1", text: "Day" },
        { value: "2", text: "Hour" },
      ],
      isBusy: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      awayunit: {
        required,
      },
      abbreviation: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      console.log("Form is", this.form);

      const form = {
        name: this.form.name,
        description: this.form.description,
        away_unit: this.form.awayunit,
        abbreviation: this.form.abbreviation,
      };

      this.isBusy = true;
      this.$store
        .dispatch("registerCompanyAwayDetail", form)
        .then((response) => {
          this.$emit("success", response.data);
        })
        .catch((reason) => {
          this.$emit("failure", reason);
          this.$bvModal.msgBoxOk("Failed to add away", {
            centered: true,
            okVariant: "danger",
          });
        })
        .finally(() => (this.isBusy = false));
    },
    close(response) {
      return response;
    },
  },
  watch: {
    isBusy(value) {
      console.log("Monitoring Busy", value);
    },
  },
};
</script>

<style scoped></style>
