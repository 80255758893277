<template>
  <b-form class="form" v-on:submit.prevent="save">
    <b-alert show="" variant="light">
      Click <strong>Submit</strong> to confirm update the Away Type
    </b-alert>
    <b-form-group id="type" label-cols-lg="2" label="Away Name">
      <b-form-input
        id="type"
        :placeholder="$t('DETAILSFORM.TYPE1')"
        v-model="$v.form.type.$model"
        :state="validateState('type')"
      >
      </b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.type.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- begin:leave unit  -->
    <b-form-group label="Away Unit" label-cols-lg="2">
      <b-form-select
        v-model="$v.form.awayunit.$model"
        :options="awayunits"
        :state="validateState('awayunit')"
      >
      </b-form-select>
      <b-form-invalid-feedback v-if="!$v.form.awayunit.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="abbreviation" label-cols-lg="2" label="Abbreviation">
      <b-form-input
        id="remark"
        v-model="$v.form.abbreviation.$model"
        :state="validateState('abbreviation')"
      ></b-form-input>
      <b-form-invalid-feedback v-if="!$v.form.abbreviation.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group id="remark" label-cols-lg="2" :label="$t('JOB.REMARK')">
      <b-form-textarea
        id="remark"
        v-model="$v.form.remark.$model"
        :state="validateState('remark')"
      ></b-form-textarea>

      <b-form-invalid-feedback v-if="!$v.form.remark.required"
        >{{ $t("ALERT.ALERT") }}
      </b-form-invalid-feedback>
    </b-form-group>

    <div class="d-flex justify-content-end border-top pt-3">
      <b-button
        ref="employee-general-button"
        variant="primary"
        type="submit"
        class="font-size-lg font-weight-bold"
        v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
      >
        {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "CompanyAwayDetailEditForm",
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      default: "Away Type Form",
    },
    formSet: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  mounted() {
    console.log("Form data set", this.formSet);
  },
  data() {
    return {
      form: {
        type: this.formSet.name,
        remark: this.formSet.description,
        abbreviation: this.formSet.abbreviation,
        awayunit: this.formSet.unit_type.id,
      },
      awayunits: [
        { value: null, text: "Please choose the away unit" },
        { value: "1", text: "Day" },
        { value: "2", text: "Hour" },
      ],
    };
  },
  validations: {
    form: {
      type: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255),
      },
      remark: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(500),
      },
      awayunit: {
        required,
      },
      abbreviation: {
        required,
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    /**
     *
     */
    save() {
      console.log("Emit Common Details to submit event");
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        console.log("Form in complete");
        return;
      }

      this.$emit("handle-submit", {
        name: this.form.type,
        description: this.form.remark,
        away_unit: this.form.awayunit,
        abbreviation: this.form.abbreviation,
      });
    },
  },
};
</script>

<style scoped></style>
