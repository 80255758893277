<template>
  <AppPageCard>
    <template #card-title> Away Type </template>
    <template #card-toolbar>
      <b-button
        class="btn btn-icon btn-light btn-hover-primary mr-3"
        v-on:click="refresh"
        v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
      >
        <span class="svg-icon svg-icon-2x svg-icon-primary">
          <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
        </span>
      </b-button>
      <b-button
        variant="light-primary"
        class="font-weight-bolder"
        v-b-toggle.company-away-detail-list-sidebar
      >
        {{ $t("EMPLOYEE.BUTTON") }}
      </b-button>
    </template>

    <CompanyAwayDetailPageTable :reload="reload"></CompanyAwayDetailPageTable>

    <b-sidebar
      ref="company-away-detail-list-sidebar-ref"
      id="company-away-detail-list-sidebar"
      backdrop
      right
      lazy
      :title="$t('USERFORM.CREATE')"
      sidebar-class="offcanvas"
      header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
      bg-variant="white"
    >
      <template v-slot:header-close>
        <button
          class="btn btn-sm btn-icon btn-light-secondary btn-hover-danger"
        >
          <i class="ki ki-close icon-xs"></i>
        </button>
      </template>
      <div class="px-10 mt-5">
        <CompanyAwayDetailCreateForm
          v-slot:default="{}"
          title="Away Details"
          v-on:success="closeSideBar"
        ></CompanyAwayDetailCreateForm>
      </div>
    </b-sidebar>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import CompanyAwayDetailCreateForm from "@/modules/company/components/away/detail/CompanyAwayDetailCreateForm";
import CompanyAwayDetailPageTable from "@/modules/company/components/away/detail/CompanyAwayDetailPageTable";
export default {
  name: "CompanyAwayDetailPage",
  components: {
    CompanyAwayDetailPageTable,
    CompanyAwayDetailCreateForm,
    AppPageCard,
  },
  data() {
    return {
      reload: false,
    };
  },
  methods: {
    closeSideBar() {
      const sideBar = this.$refs["company-away-detail-list-sidebar-ref"];
      sideBar.hide();
      this.reload = !this.reload;
    },
    refresh() {
      this.reload = !this.reload;
    },
  },
};
</script>

<style scoped></style>
